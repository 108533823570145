<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        The wavelength of a photon is listed below. Based on this value determine the frequency and
        energy (in kJ) of the photon.
      </p>

      <v-simple-table style="max-width: 550px" class="mb-7">
        <thead>
          <tr>
            <th>
              <stemble-latex style="font-size: 14px" content="$\text{Wavelength (nm)}$" />
            </th>
            <th style="text-align: center; font-size: 14px">
              <stemble-latex content="$\text{Frequency (Hz)}$" />
            </th>
            <th style="text-align: center">
              <stemble-latex style="font-size: 14px" content="$\text{Energy (kJ)}$" />
            </th>
          </tr>
          <tr>
            <td>
              <number-value :value="wavelength" />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input1"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <calculation-input
                v-model="inputs.input2"
                class="centered-input"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-2">
        <b>Helpful tip:</b> To enter a number in scientific notation, enter 1.0E5 or 1.0e5, for 1.0
        x 10<sup>5</sup> where the E# (or e#) denotes 10<sup>#</sup>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question12',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
      },
    };
  },
  computed: {
    wavelength() {
      return this.taskState.getValueBySymbol('wavelength').content;
    },
  },
};
</script>
